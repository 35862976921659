/*
 * @Description: 
 * @Author: yyh
 * @Date: 2021-07-01 14:56:15
 * @LastEditors: yyh
 * @LastEditTime: 2021-07-01 15:32:19
 */
import axios from "axios"
import { getToken, removeToken } from '../utils/auth'

export async function upDateFile(file) {
    let data = new FormData()
    data.append('file', file.file)
    let res = await axios({
        method: 'post',
        url: 'https://api.fengyechanye.com/gecs-api/common/upload',
        headers: {
            Authorization: "Bearer " + getToken(),
        },
        data: data
    })
    return res.data;
}